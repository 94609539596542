exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-julius-tsx": () => import("./../../../src/pages/about-julius.tsx" /* webpackChunkName: "component---src-pages-about-julius-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-imprint-tsx": () => import("./../../../src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-promql-cheat-sheet-tsx": () => import("./../../../src/pages/promql-cheat-sheet.tsx" /* webpackChunkName: "component---src-pages-promql-cheat-sheet-tsx" */),
  "component---src-pages-promql-compliance-test-results-2020-08-06-cortex-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2020-08-06/cortex.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2020-08-06-cortex-tsx" */),
  "component---src-pages-promql-compliance-test-results-2020-08-06-thanos-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2020-08-06/thanos.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2020-08-06-thanos-tsx" */),
  "component---src-pages-promql-compliance-test-results-2020-08-06-timescaledb-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2020-08-06/timescaledb.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2020-08-06-timescaledb-tsx" */),
  "component---src-pages-promql-compliance-test-results-2020-08-06-victoriametrics-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2020-08-06/victoriametrics.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2020-08-06-victoriametrics-tsx" */),
  "component---src-pages-promql-compliance-test-results-2020-12-01-chronosphere-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2020-12-01/chronosphere.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2020-12-01-chronosphere-tsx" */),
  "component---src-pages-promql-compliance-test-results-2020-12-01-cortex-blocks-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2020-12-01/cortex-blocks.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2020-12-01-cortex-blocks-tsx" */),
  "component---src-pages-promql-compliance-test-results-2020-12-01-cortex-chunks-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2020-12-01/cortex-chunks.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2020-12-01-cortex-chunks-tsx" */),
  "component---src-pages-promql-compliance-test-results-2020-12-01-grafanacloud-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2020-12-01/grafanacloud.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2020-12-01-grafanacloud-tsx" */),
  "component---src-pages-promql-compliance-test-results-2020-12-01-m-3-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2020-12-01/m3.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2020-12-01-m-3-tsx" */),
  "component---src-pages-promql-compliance-test-results-2020-12-01-metricfire-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2020-12-01/metricfire.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2020-12-01-metricfire-tsx" */),
  "component---src-pages-promql-compliance-test-results-2020-12-01-newrelic-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2020-12-01/newrelic.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2020-12-01-newrelic-tsx" */),
  "component---src-pages-promql-compliance-test-results-2020-12-01-promscale-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2020-12-01/promscale.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2020-12-01-promscale-tsx" */),
  "component---src-pages-promql-compliance-test-results-2020-12-01-thanos-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2020-12-01/thanos.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2020-12-01-thanos-tsx" */),
  "component---src-pages-promql-compliance-test-results-2020-12-01-victoriametrics-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2020-12-01/victoriametrics.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2020-12-01-victoriametrics-tsx" */),
  "component---src-pages-promql-compliance-test-results-2021-10-14-amp-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2021-10-14/amp.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2021-10-14-amp-tsx" */),
  "component---src-pages-promql-compliance-test-results-2021-10-14-chronosphere-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2021-10-14/chronosphere.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2021-10-14-chronosphere-tsx" */),
  "component---src-pages-promql-compliance-test-results-2021-10-14-cortex-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2021-10-14/cortex.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2021-10-14-cortex-tsx" */),
  "component---src-pages-promql-compliance-test-results-2021-10-14-gmp-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2021-10-14/gmp.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2021-10-14-gmp-tsx" */),
  "component---src-pages-promql-compliance-test-results-2021-10-14-grafanacloud-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2021-10-14/grafanacloud.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2021-10-14-grafanacloud-tsx" */),
  "component---src-pages-promql-compliance-test-results-2021-10-14-m-3-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2021-10-14/m3.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2021-10-14-m-3-tsx" */),
  "component---src-pages-promql-compliance-test-results-2021-10-14-newrelic-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2021-10-14/newrelic.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2021-10-14-newrelic-tsx" */),
  "component---src-pages-promql-compliance-test-results-2021-10-14-promscale-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2021-10-14/promscale.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2021-10-14-promscale-tsx" */),
  "component---src-pages-promql-compliance-test-results-2021-10-14-sysdig-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2021-10-14/sysdig.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2021-10-14-sysdig-tsx" */),
  "component---src-pages-promql-compliance-test-results-2021-10-14-thanos-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2021-10-14/thanos.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2021-10-14-thanos-tsx" */),
  "component---src-pages-promql-compliance-test-results-2021-10-14-victoriametrics-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2021-10-14/victoriametrics.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2021-10-14-victoriametrics-tsx" */),
  "component---src-pages-promql-compliance-test-results-2022-05-19-vmwarewavefront-tsx": () => import("./../../../src/pages/promql-compliance-test-results/2022-05-19/vmwarewavefront.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-test-results-2022-05-19-vmwarewavefront-tsx" */),
  "component---src-pages-promql-compliance-tests-tsx": () => import("./../../../src/pages/promql-compliance-tests.tsx" /* webpackChunkName: "component---src-pages-promql-compliance-tests-tsx" */),
  "component---src-pages-promql-training-tsx": () => import("./../../../src/pages/promql-training.tsx" /* webpackChunkName: "component---src-pages-promql-training-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

